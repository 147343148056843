.about-container {
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color:transparent;
}

.about-content {
  width: 100%;
  background-color: #fff;
  padding: 50px  0px;
}

.about-heading {
  font-size: 44px;
  color: #0d0d0d;
  margin-bottom: 20px;
  text-align: left;
}

.about-subheading {
  text-align: left;
  font-size: 32px;
  color: #080808;
  margin-top: 40px;
  margin-bottom: 20px;
}

.about-paragraph {
  font-size: 18px;
  color: #070707;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

.about-list {
  list-style-type: disc;
  margin-left: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #070707;
  text-align: left;
}

.about-list li {
  margin-bottom: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .about-heading {
    font-size: 36px;
  }

  .about-subheading {
    font-size: 28px;
  }

  .about-paragraph, .about-list {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .about-heading {
    font-size: 30px;
  }

  .about-subheading {
    font-size: 24px;
  }

  .about-paragraph, .about-list {
    font-size: 14px;
  }
}
