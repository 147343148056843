/* Container and Layout */

.contact-main-container {
  margin-top: 70px;
  margin-bottom: 100px;
}

.cont-h {
  padding-top: 60px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-top: 70px;
}

.contact-container {
  display: flex;
  justify-content: center;
  padding: 30px;
  background-color: #ffffff;
  width: 100%;
}

.contact-inner-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-info {
  background-color: #eef8fc;
  border-radius: 12px;
  padding: 50px;
  width: 45%;
}

.contact-title {
  font-size: 28px;
  color: #222;
  font-weight: bold;
  margin-bottom: 2px;
}

.contact-description {
  color: #666;
  margin-bottom: 10px;
}

.contact-details {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.icon {
  width: 24px;
  height: 24px;
  color: #1e90ff;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.social-icon {
  width: 30px;
  height: 30px;
}

.contact-form {
  width: 45%;
  padding: 30px;
}

.input-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.input-field,
.textarea-field {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-input {
  margin-right: 10px;
}

.checkbox-text {
  font-size: 16px;
}

.submit-buttonn {
  background-color: #467cbf;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.submit-buttonn:hover {
  background-color: #f8b024;
}

@media (max-width: 768px) {
  .cont-h {
    font-size: 28px;
    margin-top: 70px;
  }
  .contact-container {
    padding: 0px 20px;
  }
  .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-info {
    width: 35%;
  }
  .contact-form {
    width: 35%;
  }
  .contact-form form {
    margin-right: 50px;
  }
  .submit-buttonn {
    background-color: #467cbf;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .submit-buttonn:hover {
    background-color: #f8b024;
  }
}

@media (max-width: 480px) {
  .cont-h {
    font-size: 24px;
    margin-top: 5px;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin-top: 10px;
  }
  .contact-info {
    padding: 30px;
    width: 100%;
    margin-right: 20px;
    margin-left: 20px;
  }
  .contact-form {
    width: 100%;
  }
  .contact-form form {
    margin: 0px 30px;
  }
  .contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .submit-buttonn {
    background-color: #467cbf;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .submit-buttonn:hover {
    background-color: #f8b024;
  }
}
