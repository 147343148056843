.refund-container {
  display: flex;
  margin: 110px 0px;
  padding: 30px 20px;
}

.refunds-content {
  width: 100%;
  padding: 30px 55px;
  margin-top: 85px;
  text-align: left;
}

.refunds-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 16px;
}

.refunds-subheading {
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 8px;
}

.refunds-paragraph {
  font-size: 19px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 12px;
}

@media (max-width: 480px) {
  .refund-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    flex-direction: column;
  }
  .refunds-content {
    width: 100%;
    margin-top: 30px;
    text-align: left;
    margin-left: 0px;
    padding: 0px 0px;
  }
}

@media (max-width: 768px) {
  .refund-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    flex-direction: column;
  }
  .refunds-content {
    width: 100%;
    padding: 30px 75px;
    margin-top: 65px;
    margin-left: 20px;
    text-align: left;
  }
}

@media (max-width: 1440px) {
  .refund-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 40px;
    flex-direction: column;
  }
  .refunds-content {
    width: 100%;
    padding: 55px 95px;
    margin-right: 80px;
    margin-left: 30px;
    text-align: left;
  }
}
