/* 1. Global box-sizing reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.header {
  width: 100%;
  background-color: #f5f9fc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header-spacing {
  height: 70px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  max-width: 1200px;
  height: 9vw;
  margin: auto;
}

.logo img {
  max-width: 15vw;
}

.nav-button-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.nav-links {
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.nav-item {
  text-decoration: none;
  font-size: 18px;
  color: #222;
  font-weight: 500;
  transition: color 0.3s;
}

.nav-item:hover {
  color: #467cbf;
}

.contact-button {
  padding: 10px 20px;
  background-color: #467cbf;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s;
}

.contact-button button:hover {
  background-color: #f8b024;
  color: #467cbf;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #222;
  margin: 4px 0;
  transition: 0.4s;
}

/* Media Queries */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-button-container {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #f5f9fc;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 999;
  }

  .nav-button-container.open {
    display: flex;
  }

  .nav-item {
    text-wrap: nowrap;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .hamburger {
    display: flex;
  }
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    max-width: 1200px;
    height: 8vh;
    margin: auto;
  }

  .nav-button-container {
    margin-top: -2%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
  }
  .nav-button-container {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #f5f9fc;
    position: absolute;
    top: 75px;
    left: 0;
    z-index: 999;
  }

  .nav-button-container.open {
    display: flex;
  }

  .nav-item {
    text-wrap: nowrap;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
  .logo img {
    max-width: 20vh;
  }
}
