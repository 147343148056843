.vin-search-container {
  overflow-x: hidden;
  width: 100%;
  background-color: #e3f7ff;
  padding: 50px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.vin-search-content {
  width: 100%;
  height: 40vw; /* Adjust height as needed */
}
.new-content {
  width: 50%;
  margin-top: 45px;
  position: block;
  text-align: center;
  padding-right: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: end;
}

.vin-subtitle {
  margin-top: 80px;
  color: #0c0c0c;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 20px;
  font-style: bold;
  text-align: center;
  justify-content: center;
}
.vinn-title {
  font-size: 30px;
  font-style: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #0c0c0c;
}

/* .vin-form-container {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 999;
  border-radius: 25px;

  padding: 20px 50px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(19, 19, 19, 0.1);
} */

.vin-input-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px;
  border-radius: 25px;
  background-color: #ffffff;
  transition: border 0.3s ease;
  width: 40vw;
  margin: 0 auto;
  margin-left: 10px;
}

.vin-input-wrapper:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

/* Input Field */
.vin-input {
  flex: 1;
  padding: 12px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #0f0f0f;
  border-radius: 25px;
  margin-left: 8px;
}

/* Submit Button */
.vin-submit-btnn {
  padding: 14px 30px;
  border: none;
  outline: none;
  background-color: #467cbf; /* Theme color */
  color: #fff;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease;
}

.vin-submit-btnn:hover {
  background-color: #f8b024;
  color: #0f0f0f;
}

.vin-submit-btn:hover {
  background-color: #f8b024;
  color: #467cbf;
}
.how-it-works {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 30px;
}

.how-it-works .text-center h3 {
  color: #467cbf;
  font-size: 22px;
  margin-bottom: 15px;
}

.how-it-works .text-center h2 {
  color: #0f0f0f;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.3em;
  text-align: center;
  padding: 0 20px;
}

.steps-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.step-item {
  width: 300px;
  margin: 0 20px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.step-item:hover {
  transform: translateY(-10px);
}

.step-image {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  background-color: #f8b024;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.step-title {
  font-size: 22px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #0f0f0f;
}

.step-description {
  font-size: 16px;
  color: #0f0f0f;
  margin: 0 auto;
  max-width: 250px;
}
.card-h h1 {
  padding: 3px 15px;

  font-size: 25px;
}
.card-h p {
  font-size: 22px;
}
.card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex: 1 30%;
  margin: 12px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
  margin-left: 20px;
}

.card img {
  width: 50px;
  height: auto;
  margin-bottom: 15px;
}

.card h3 {
  font-size: 1.2em;
  color: #0f0f0f;
  margin-bottom: 10px;
}

.card p {
  font-size: 0.9em;
  color: #666;
}

.card:hover {
  transform: scale(1.03);
  border-color: #467cbf;
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
  border-width: 2px;
}
.card img {
  width: 50px;
  height: auto;
  margin-bottom: 15px;
  transition: filter 0.3s ease-in-out;
}

.card:hover img {
  filter: brightness(0) saturate(100%) invert(52%) sepia(87%) saturate(1036%)
    hue-rotate(1deg) brightness(100%) contrast(99%);
}

@media (max-width: 768px) {
  /* .vin-form-container {
    background-color: #e3f7ff;
    padding: 10px 0px;
    width: 95%;
    box-shadow: 0 14px 18px rgba(44, 44, 44, 0.1);
    display: flex;
    align-self: center;
    justify-content: center;
    border-radius: 10px;
    margin-left: 50px;
  } */

  .vin-input-wrapper {
    width: 70vw;
    padding: 3px;
  }

  .vin-input {
    padding: 4px;
    font-size: 14px;
  }

  .vin-submit-btnn {
    width: 15vw;
    padding: 10px 20px;
    font-size: 14px;
  }

  .card {
    flex: 1 1 30%;
    margin: 15px;
    padding: 10px 75px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 480px) {
  .vin-search-container {
    overflow-x: hidden;
    width: 100%;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .vin-search-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .new-content {
    margin-top: 108px;
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .vin-subtitle {
    margin-top: 20px;
    color: #0c0c0c;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 15px;
    font-style: bold;
  }

  .vinn-title {
    margin-top: 5px;
    font-size: 20px;
    font-style: bold;
  }

  .vin-form-container {
    padding: 0px 0px;
    width: 100%;
    box-shadow: 0 14px 18px rgba(38, 127, 237, 0.1);
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 30px;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }

  .vin-input-wrapper {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 65px;
    background-color: #ffffff;
    transition: border 0.3s ease;
    padding: 7px;
    width: 100%;
  }

  .vin-input-wrapper:hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  /* Input Field */
  .vin-input {
    flex: 1;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #000;
    border-radius: 45px;
  }

  /* Submit Button */
  .vin-submit-btnn {
    padding: 6px 7px;
    border: none;
    outline: none;
    background-color: #467cbf; /* Theme color */
    color: #fff;
    border-radius: 25px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 700;
    width: 30%;
    text-wrap: nowrap;
    transition: background-color 0.3s ease;
  }

  .vin-submit-btnn:hover {
    background-color: #f8b024;
    color: #252525;
  }

  .how-it-works {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
  .how-it-works .text-center h3 {
    color: #467cbf;
    font-size: 22px;
    margin-bottom: 15px;
  }

  .how-it-works .text-center h2 {
    color: #222;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.3em;
    text-align: center;
    padding: 0 20px;
  }
  .steps-container {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .step-item {
    width: 300px;
    margin: 0 20px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }

  .step-item:hover {
    transform: translateY(-10px);
  }

  .how-it-works .text-center h3 {
    color: #467cbf;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .how-it-works .text-center h2 {
    color: #222;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.3em;
    text-align: center;
    padding: 0 20px;
  }

  .step-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px auto;
    border-radius: 50%;
    background-color: #f8b024;
    align-self: center;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  .card-h h1 {
    padding: 2px 7px;
    color: #010101;
    font-size: 18px;
  }
  .card-h p {
    font-size: 17px;
    color: #010101;
  }

  .card {
    flex: 45%;
    margin: 10px 20px;
    padding: 35px 25px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    align-self: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-left: 20px;
  }
}
